import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import "./PromoEvents.css";
import Slider from "react-slick";

const PromoEvents = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [promos, setPromos] = useState(null);

  var settings = {
    dots: true,
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  async function getPromos() {
    try {
      const res = await axios.get("/api/promo/get-promos");
      if (res.data.success) {
        setPromos(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPromos();
  }, []);

  return (
    <div className="promo-event-container">
      <h2>Promo & News</h2>

      <div className="promo-blogs-container">
        <Slider {...settings}>
          {promos?.map((item, index) => (
            <div className="promoo">
              <div
                className="blog"
                onClick={() => navigate(`/promo/${item._id}`)}
                key={index}
              >
                <img src={`/${item?.image}`} alt="" />
                <div className="content">
                  <span>
                    {new Date(item?.date).toLocaleString("default", {
                      day: "numeric",
                      long: "long",
                      year: "numeric",
                    })}{" "}
                    | {item?.category}
                  </span>
                  <h5>{item?.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PromoEvents;
