import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setSearch } from "../../redux/features/searchSlice.js";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tippy from "@tippyjs/react";
import LogoutTippy from "./LogoutTippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import SearchContainer from "../SearchContainer";
import CartSideMenu from "../CartSideMenu";
import ShareIcon from "@mui/icons-material/Share";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AddBoxIcon from "@mui/icons-material/AddBox";
import getUserData from "../../utils/userDataService.js";
import IMAGES from "../../img/image.js";
import "./Header.css";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const { search } = useSelector((state) => state.search);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [notification, setNotification] = useState("");
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  async function getNoti() {
    try {
      const res = await axios.get("/api/noti/get-noti");
      if (res.data.success) {
        setNotification(res.data.data[0]);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getNoti();
  }, []);
  function handleShare() {
    const websiteUrl = "https://saichingstore.com";
    const message = `Game Instant Recharge Website Link: ${websiteUrl}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  }

  return (
    <>
      {location.pathname === "/" && notification?.topDisplay === "yes" && (
        <div className="top-notification">
          <div className="notification">
            <img src={notification?.topImg} alt="" />
            <div>
              <h5>{notification?.topName}</h5>
              <Link to={notification?.topLink} target="_blank">
                Click Here
              </Link>
            </div>
          </div>
        </div>
      )}
      <header className="header">
        <div className="topheader">
          <div className="logo" onClick={() => navigate("/")}>
            <p>
              <b>
                SAICHING<span className="ffca00">STORE</span>
              </b>
            </p>
          </div>
          <div className="icons">
            <ShareIcon className="icon" onClick={handleShare} />
            <SupportAgentIcon
              className="icon"
              onClick={() => navigate("/contact")}
            />
            {user && (
              <NotificationsNoneIcon
                className="icon"
                onClick={() => navigate("/notification")}
              />
            )}
          </div>
        </div>
        <div className="header-main">
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <div
            className="burger-icon d-block d-lg-none"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <MenuIcon className="icon" />
          </div>
          <div className="menus d-none d-md-none d-lg-block">
            <ul className="p-0">
              <li>
                <Link to="/games">Games</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              {!user && (
                <li>
                  <Link to="/login">My Account</Link>
                </li>
              )}
              {user && (
                <li>
                  <Link to="/user-dashboard">Dashboard</Link>
                </li>
              )}
              <li>
                <Link to="/reseller">Reseller</Link>
              </li>
            </ul>
          </div>
          <div className="action-btns">
            {user && (
              <div className="balance" onClick={() => navigate("/wallet")}>
                <img width="30px" src={IMAGES.coin} alt="" />
                {balance}
                <AddBoxIcon className="icon" />
              </div>
            )}
            <SearchIcon
              onClick={() => {
                dispatch(setSearch(!search));
              }}
              className="icon"
            />
            <Tippy
              interactive
              theme="light"
              content={<LogoutTippy user={user && user} />}
            >
              <span className="menu-img-container d-flex">
                <PersonIcon
                  className="icon d-lg-block d-md-none d-none"
                  onClick={() => navigate("/login")}
                />
                {user && (
                  <KeyboardArrowDownIcon className="d-lg-block d-md-none d-none text-white" />
                )}
              </span>
            </Tippy>
          </div>
        </div>
      </header>
      <CartSideMenu cartMenu={cartMenu} setCartMenu={setCartMenu} />
      <SearchContainer search={search} setSearch={setSearch} />
    </>
  );
};

export default Header;
