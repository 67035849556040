import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import getUserData from "../utils/userDataService.js";
import { setUser } from "../redux/features/userSlice.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import WalletIcon from "@mui/icons-material/Wallet";
import InfoIcon from "@mui/icons-material/Info";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SafetyCheckIcon from "@mui/icons-material/SafetyCheck";
import LockResetIcon from "@mui/icons-material/LockReset";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpIcon from "@mui/icons-material/Help";
import VerifiedIcon from "@mui/icons-material/Verified";
import "./ProductInfo.css";
import Spinner from "../components/loader/Spinner.js";
import Slider from "react-slick";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [userAllow, setUserAllow] = useState(false);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("WALLET");
  const [paymentOptions, setPaymentOptions] = useState("");
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [offerPrice, setOfferPrice] = useState(null);
  const [productId, setProductId] = useState("");
  const [profit, setProfit] = useState("");
  //! API BASED
  const [orderId, setOrderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [balance, setBalance] = useState("");
  const [orderSuccess, setOrderSuccess] = useState(true);
  const [data, setData] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [couponApplied, setCouponApplied] = useState(null);
  const [discount, setDiscount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [couponName, setCouponName] = useState("");
  const [showBuyPopup, setShowBuyPopup] = useState(false);
  const [pCategory, setPCategory] = useState(null);
  const [notification, setNotification] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
    getAllCoupons();
  }, []);

  const getAllCoupons = async () => {
    try {
      const res = await axios.get("/api/admin/get-coupons");
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function addPercentage(price, offer) {
    const discountedPrice =
      parseFloat(price) + (parseFloat(price) / 100) * parseFloat(offer);
    return Math.ceil(discountedPrice);
  }

  function applyCoupon(e) {
    e.preventDefault();
    if (data?.length === 0 || data === null) {
      return message.error("No Coupon Found");
    }
    if (coupon === "") {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1500);
      return;
    }
    // find coupon
    const couponCode = data && data?.find((item) => item.name === coupon);
    if (!couponCode) {
      return message.error("No coupon found");
    }
    setCouponName(couponCode?.name);

    if (couponCode?.minValue >= selectedPrice) {
      message.error(`Minimum order value is: ${couponCode?.minValue}`);
      return;
    }
    //
    if (coupon) {
      setCouponApplied(true);
      setDiscount(couponCode?.discount);
      setFinalAmount(selectedPrice - couponCode?.discount);
      message.success("Coupon applied success");
    } else {
      message.error("No coupon found");
    }
  }

  const removeDiscount = () => {
    setCouponApplied(false);
    setFinalAmount((prev) => prev + discount);
  };

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      const offerPercentage = product?.cost?.find(
        (item) => item.amount === amount
      )?.offer;
      const op = addPercentage(price, offerPercentage);
      setSelectedPrice(price);
      setFinalAmount(price);
      setOfferPrice(op);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setProfit(pf);
      if (couponApplied) {
        setCouponApplied(false);
      }
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      const offerPercentage = product?.cost?.find(
        (item) => item.amount === amount
      )?.offer;
      const op = addPercentage(price, offerPercentage);
      setSelectedPrice(price);
      setFinalAmount(price);
      setOfferPrice(op);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      const pf = product?.cost?.find((item) => item.amount === amount)?.profit;
      setProfit(pf);
      if (couponApplied) {
        setCouponApplied(false);
      }
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        const defaultOriginalPrice = res.data.data?.cost?.[0]?.profit;
        const defaultCategory = res.data.data?.cost?.[0]?.category;
        const defaultOfferPrice = addPercentage(
          defaultPrice,
          res.data.data?.cost?.[0]?.offer
        );
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setFinalAmount(defaultPrice);
        setProductId(defaultId);
        setProfit(defaultOriginalPrice);
        setPCategory(defaultCategory);
        setOfferPrice(defaultOfferPrice);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProduct();
  }, [user]);

  // handle popup
  useEffect(() => {
    setTimeout(() => {
      setNotification(true);
    }, 2000);
  }, []);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter User ID" : "Enter (   Zone ID   )"}`
      );
    }
    try {
      const object = {
        region: "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: "13",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
        if (product?.use === res.data.use) {
          setUserAllow(true);
        } else {
          setUserAllow(false);
        }
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function checkPlaceOrder(e) {
    if (product?.api === "no") {
      if (userId === "") {
        return message.error("Some Fields are missing");
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
      if (
        product?.gameName === "Clash Of Clans" ||
        product?.gameName === "Free Fire"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
      if (
        product?.gameName === "Genshin Impact" ||
        product?.gameName === "ML Region Luar" ||
        product?.gameName === "Mobile Legends"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    } else {
      if (userId === "") {
        return message.error("Enter User ID");
      }
      if (zoneId === "") {
        return message.error("Enter Zone ID");
      }
      if (playerCheck === null) {
        return message.error("Check Your Player Name");
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (mode === "UPI") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else if (product?.apiName === "smileOne") {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (product?.apiName === "moogold") {
        if (mode === "UPI") {
          handleMoogoldUpiOrder(e);
        } else {
          handleMoogoldWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // yokcash
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://saichingstore.com/api/yokcash/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post("/api/yokcash/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function handleYokcashWalletOrder(e) {
    if (parseInt(balance) < selectedPrice) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      const res = await axios.post("/api/yokcash/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // smile
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://saichingstore.com/api/smile/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };
      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < selectedPrice) {
      return message.error("Balance is less for this order");
    }
    try {
      e.preventDefault();
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        productid: productId,
        region: product?.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product.name,
        amount: amount,
        price: selectedPrice,
      };

      setLoading(true);
      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // moogold
  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://saichingstore.com/api/moogold/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };
      setLoading(true);

      const response = await axios.post("/api/moogold/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    e.preventDefault();
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const orderObject = {
        api: "no",
        order_id: orderId,
        txn_amount: finalAmount,
        product_name: product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/success");
      } else {
        setLoading(false);
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // manual
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://saichingstore.com/api/manaul/status?orderId=${orderId}`,
        txn_note: userId + "@" + amount,
      };

      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleWalletOrder = async (e) => {
    e.preventDefault();
    if (parseInt(balance) < selectedPrice) {
      return message.error("Balance is less for this order");
    }
    try {
      setLoading(true);
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        productid: productId,
        region: productId?.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoading(false);
        message.success(res.data.message);
        navigate("/orders");
        localStorage.removeItem("cart");
        localStorage.setItem("orderProcess", "no");
      } else {
        message.error(res.data.message);
        setLoading(false);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  const uniqueCategories = Array.from(
    new Map(product?.cost?.map((item) => [item.category, item])).values()
  );

  return (
    <Layout>
      <div className="product-info-container">
        {loading && (
          <div className="loading-container">
            <Spinner />
          </div>
        )}

        {notification && (
          <div className="notification-modal">
            <div className="notification">
              <h4>{product?.notificationHeading}</h4>
              <p>{product?.notificationDescOne}</p>
              <p>{product?.notificationDescTwo}</p>
              <button onClick={() => setNotification(false)}>Close</button>
            </div>
          </div>
        )}

        {/* TOP BANNER  */}
        <div className="topbanner">
          <img src={`https://saichingstore.com/${product?.topBanner}`} alt="" />
        </div>

        {/* GAME  */}
        <div className="game-name">
          <div className="image">
            <img src={`https://saichingstore.com/${product?.image}`} alt="" />
          </div>
          <div className="content">
            <h4>{product?.name}</h4>
            <div className="features">
              <button>
                <SafetyCheckIcon className="icon me-1" />
                100% Secure
              </button>
              <button>
                <ElectricBoltIcon className="icon me-1" />
                Instant Delivery
              </button>
            </div>
          </div>
        </div>

        {/* HBANNERS  */}
        <div className="hbanners">
          <Slider {...settings}>
            {product?.hbanners?.map((elm, index) => {
              return (
                <div className="hbannerimg">
                  <img src={`https://saichingstore.com/${elm}`} alt="" />
                </div>
              );
            })}
          </Slider>
        </div>
        {/* DESC & YOUTUBE LINK */}
        {/* <div className="yt-container bg-fields">
          <h4>Game Description</h4>
          <p>{product?.descTwo}</p>
          <div className="yt">
            <Link to={product?.youtubeLink} target="_blank">
              <button className="ytbtn">
                <InfoIcon className="icon text-white" />
                How to Purchase
              </button>
            </Link>
          </div>
        </div> */}

        <div className="product-info-content mb-2">
          <div className="left">
            {/* ====================== USER FIELDS ===============  */}
            {/* ====================== USER FIELDS ===============  */}
            {/* ====================== USER FIELDS ===============  */}
            <div className="bg-fields d-block d-md-block d-lg-none">
              <h5>Enter IDs</h5>
              <hr />
              {product?.apiName === "smileOne" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-noww" onClick={handleCheckPlayer}>
                    Check Playername
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && userAllow && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                  {playerCheck !== null && !userAllow && (
                    <p className="playername text-white bg-danger">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "ML Region Luar" ||
                  product?.gameName === "Mobile Legends") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-noww mb-2" onClick={handleCheckPlayer}>
                    Check Playername
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Genshin Impact" ||
                  product?.gameName === "Honkai Star Rail") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW_HK_MO">TW_HK_MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Free Fire" ||
                  product?.gameName === "Call Of Duty Mobile" ||
                  product?.gameName === "PUBG Mobile" ||
                  product?.gameName === "Valorant" ||
                  product?.gameName === "Clash Of Clans" ||
                  product?.gameName === "League of Legends Wild Rift") ? (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              ) : product?.apiName === "moogold" &&
                (product?.gameName === "15145" ||
                  product?.gameName === "2362359" ||
                  product?.gameName === "5846232" ||
                  product?.gameName === "6637539" ||
                  product?.gameName === "8957883" ||
                  product?.gameName === "8996566" ||
                  product?.gameName === "4690648") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-noww" onClick={handleCheckPlayer}>
                    Check Playername
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "moogold" &&
                product?.gameName === "428075" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW, HK, MO">TW, HK, MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              )}
            </div>
            {/* ====================== PACKAGE ===============  */}
            {/* ====================== PACKAGE ===============  */}
            {/* ====================== PACKAGE ===============  */}
            <div className="bg-fields category-container">
              <h5>Select Category</h5>
              <hr />
              <div className="pcategory">
                {uniqueCategories.map((item, index) => (
                  <div key={index}>
                    <div
                      className={`pc ${
                        pCategory === item?.category && "active"
                      }`}
                      onClick={() => setPCategory(item?.category)}
                    >
                      <img src={item?.cimg} alt="" />
                      <p>{item?.category}</p>
                    </div>
                  </div>
                ))}
              </div>
              <hr />
              <div className="p-amount">
                {product?.cost
                  ?.filter((item) => item?.category?.includes(pCategory))
                  .map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setAmount(item.amount);
                          setPriceAndId(item.amount);
                        }}
                        key={index}
                        className={`amount ${
                          amount === item?.amount && "active"
                        }`}
                      >
                        <div className="offertag">
                          {item?.tag ? item?.tag : item?.offer + "% OFF"}
                        </div>
                        <div className="price">
                          <p className="m-0">{item?.amount}</p>
                          <span className="m-0">
                            ₹
                            {user?.reseller === "yes"
                              ? addPercentage(item?.resPrice, item?.offer)
                              : addPercentage(item?.price, item?.offer)}
                          </span>
                          {amount === item?.amount && (
                            <div className="offershow">
                              <p>
                                <small>
                                  total:{" "}
                                  {user?.reseller === "yes"
                                    ? addPercentage(item?.resPrice, item?.offer)
                                    : addPercentage(item?.price, item?.offer)}
                                </small>
                              </p>
                              <p>
                                <small>
                                  reduced:{" "}
                                  {user?.reseller === "yes"
                                    ? item?.resPrice
                                    : item?.price}
                                </small>
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="pimg">
                          <img src={item?.pimg} alt="" />{" "}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* ====================== DESC FOR DESKTOP ===============  */}
            {/* ====================== DESC FOR DESKTOP ===============  */}
            {/* ====================== DESC FOR DESKTOP ===============  */}
            <div className="bg-fields text-white d-none d-md-none d-lg-block descImg">
              <img src={product?.descImg} width="100%" alt="" />
              <p>{product?.descThree}</p>
            </div>
          </div>
          <div className="right">
            {/* ====================== USER FIELDS ===============  */}
            {/* ====================== USER FIELDS ===============  */}
            {/* ====================== USER FIELDS ===============  */}
            <div className="bg-fields d-none d-md-none d-lg-block">
              <h5>Enter IDs</h5>
              <hr />
              {product?.apiName === "smileOne" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-noww" onClick={handleCheckPlayer}>
                    Check Playername
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && userAllow && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                  {playerCheck !== null && !userAllow && (
                    <p className="playername text-white bg-danger">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "ML Region Luar" ||
                  product?.gameName === "Mobile Legends") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-noww mb-2" onClick={handleCheckPlayer}>
                    Check Playername
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Genshin Impact" ||
                  product?.gameName === "Honkai Star Rail") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW_HK_MO">TW_HK_MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Free Fire" ||
                  product?.gameName === "Call Of Duty Mobile" ||
                  product?.gameName === "PUBG Mobile" ||
                  product?.gameName === "Valorant" ||
                  product?.gameName === "Clash Of Clans" ||
                  product?.gameName === "League of Legends Wild Rift") ? (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              ) : product?.apiName === "moogold" &&
                (product?.gameName === "15145" ||
                  product?.gameName === "2362359" ||
                  product?.gameName === "5846232" ||
                  product?.gameName === "6637539" ||
                  product?.gameName === "8957883" ||
                  product?.gameName === "8996566" ||
                  product?.gameName === "4690648") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-noww" onClick={handleCheckPlayer}>
                    Check Playername
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "moogold" &&
                product?.gameName === "428075" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW, HK, MO">TW, HK, MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              )}
            </div>

            {/* ====================== PAYMENTS ===============  */}
            {/* ====================== PAYMENTS ===============  */}
            {/* ====================== PAYMENTS ===============  */}
            <div className="bg-fields paymentcontainer">
              <h5>Payment Method</h5>
              <hr />
              <div
                className={`payments ${mode === "WALLET" && "active"}`}
                onClick={() => {
                  setMode("WALLET");
                }}
              >
                <span>
                  <img src={IMAGES.wallet} alt="" />
                  Wallet
                </span>
                <div className="pprice">
                  <span>{selectedPrice}</span>
                </div>
              </div>
              <div
                className={`payments ${
                  mode === "UPI" && paymentOptions === "GPAY" && "active"
                }`}
                onClick={() => {
                  setMode("UPI");
                  setPaymentOptions("GPAY");
                }}
              >
                <span>
                  <img src={IMAGES.gpay} alt="" />
                  Gpay
                </span>
                <div className="pprice">
                  <span>{selectedPrice}</span>
                </div>
              </div>
              <div
                className={`payments ${
                  mode === "UPI" && paymentOptions === "PAYTM" && "active"
                }`}
                onClick={() => {
                  setMode("UPI");
                  setPaymentOptions("PAYTM");
                }}
              >
                <span>
                  <img src={IMAGES.paytm} alt="" />
                  Paytm
                </span>
                <div className="pprice">
                  <span>{selectedPrice}</span>
                </div>
              </div>
              <div
                className={`payments ${
                  mode === "UPI" && paymentOptions === "PHONEPE" && "active"
                }`}
                onClick={() => {
                  setMode("UPI");
                  setPaymentOptions("PHONEPE");
                }}
              >
                <span>
                  <img src={IMAGES.phonpe} alt="" />
                  Phonepe
                </span>
                <div className="pprice">
                  <span>{selectedPrice}</span>
                </div>
              </div>
            </div>

            {/* ====================== COUPON ===============  */}
            {/* ====================== COUPON ===============  */}
            {/* ====================== COUPON ===============  */}
            {/* <div className="bg-fields">
              {couponApplied ? (
                <>
                  <h5>Discount Applied</h5>
                  <div className="coupon-tag">
                    <p className="m-0">
                      {couponName} <CheckCircleOutlineIcon className="icon" />
                    </p>
                    <button className="remove-coupon" onClick={removeDiscount}>
                      Remove
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h5>Apply Coupon</h5>
                  <div className="coupon-box">
                    <input
                      className="player-tag m-0"
                      type="text"
                      name="coupon"
                      placeholder="Enter Coupon"
                      onChange={(e) => setCoupon(e.target.value)}
                      value={coupon}
                    />
                    <button onClick={applyCoupon}>Apply</button>
                  </div>
                </>
              )}
              {error && coupon === "" && (
                <span className="text-danger">Enter valid coupon</span>
              )}
            </div> */}
            {/* ====================== BUY NOW BUTTON ===============  */}
            {/* ====================== BUY NOW BUTTON ===============  */}
            {/* ====================== BUY NOW BUTTON ===============  */}
            <div className="bg-fields buynowcontainer">
              <div className="buynowprice">
                <p>
                  Final <b>₹{selectedPrice}</b>
                </p>
                {!isNaN(offerPrice) && (
                  <p>
                    <small>Total ₹{offerPrice}</small>
                  </p>
                )}
                <p></p>
              </div>
              {!user ? (
                <button className="buy-now" onClick={() => navigate("/login")}>
                  Please Login to Continue
                </button>
              ) : product?.stock === "No" ? (
                <button className="buy-now" style={{ opacity: "0.7" }}>
                  Out of stock
                </button>
              ) : (
                <button
                  onClick={() => setShowBuyPopup(true)}
                  className="buy-now"
                >
                  Buy Now
                </button>
              )}
            </div>

            {/* ====================== DESC FOR MBOBILE ===============  */}
            {/* ====================== DESC FOR MBOBILE ===============  */}
            {/* ====================== DESC FOR MBOBILE ===============  */}
            <div className="bg-fields text-white d-block d-md-block d-lg-none descImg">
              <img src={product?.descImg} width="100%" alt="" />
              <p>{product?.descThree}</p>
            </div>

            {/* ====================== CONFIRM POPUP ===============  */}
            {/* ====================== CONFIRM POPUP ===============  */}
            {/* ====================== CONFIRM POPUP ===============  */}
            <div
              className={`${showBuyPopup && "bd"}`}
              onClick={() => setShowBuyPopup(!showBuyPopup)}
            ></div>
            <div className={`buy-popup ${showBuyPopup && "active"}`}>
              <h2>Please Confirm your order details!</h2>
              <div className="orderdetails">
                <div className="confirmproduct">
                  <div className="img">
                    <img
                      src={`https://saichingstore.com/${product?.image}`}
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h5>{amount}</h5>
                    <p>{product?.name}</p>
                    {playerCheck && (
                      <span>
                        {playerCheck}
                        <VerifiedIcon className="icon" />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="item-container">
                <div className="item">
                  <span>Item</span>
                  <span>{amount}</span>
                </div>
                <div className="item">
                  <span>User ID</span>
                  <span>{userId}</span>
                </div>
                {zoneId && (
                  <div className="item">
                    <span>Zone ID</span>
                    <span>{zoneId}</span>
                  </div>
                )}
                <div className="item">
                  <span>Payment Method</span>
                  <span>{mode}</span>
                </div>
                <div className="item">
                  <span>Price</span>
                  <span>{finalAmount}</span>
                </div>
              </div>
              <div className="confirmdetails">Confirm Your Order</div>
              <div className="buybtns">
                <button
                  className="cancelbtn"
                  onClick={() => setShowBuyPopup(false)}
                >
                  cancel
                </button>
                <button
                  disabled={loading}
                  onClick={(e) => checkPlaceOrder(e)}
                  className="confirmbtn"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductInfo;
