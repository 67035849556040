import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import AdminLayout from "./components/AdminLayout";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import "./AdminAddProduct.css";
import "./AdminUsers.css";

const AdminAddProduct = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [topBanner, setTopBanner] = useState(null);
  const [hBanners, setHBanners] = useState([]);
  const [descImg, setDescImg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [images, setImages] = useState(null);
  const [yokcash, setYokcash] = useState(null);

  const [form, setForm] = useState({
    name: "",
    desc: "",
    descTwo: "",
    descThree: "",
    api: "",
    apiName: "",
    gameName: "",
    category: "",
    region: "",
    tag: "",
    stock: "Yes",
    notificationHeading: "",
    notificationDescOne: "",
    notificationDescTwo: "",
  });

  const [cost, setCost] = useState([
    {
      id: "",
      amount: "",
      price: "",
      pimg: "",
      resPrice: "",
      profit: "",
      category: "",
      cimg: "",
      tag: "",
      offer: "",
    },
  ]);

  const handleCategoryChange = (selectedList) => {
    setForm((prevForm) => ({
      ...prevForm,
      category: selectedList,
    }));
  };

  const handleAddCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index + 1, 0, {
      id: "",
      amount: "",
      price: "",
      pimg: "",
      resPrice: "",
      profit: "",
      category: "",
      cimg: "",
      tag: "",
      offer: "",
    });
    setCost(updatedCost);
  };

  const handleRemoveCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index, 1);
    setCost(updatedCost);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setForm({ ...form, [name]: e.target.files });
    } else if (
      name.startsWith("id") ||
      name.startsWith("amount") ||
      name.startsWith("price") ||
      name.startsWith("pimg") ||
      name.startsWith("resPrice") ||
      name.startsWith("profit") ||
      name.startsWith("category") ||
      name.startsWith("cimg") ||
      name.startsWith("tag") ||
      name.startsWith("offer")
    ) {
      const index = parseInt(name.split("-")[1]);
      const updatedCost = [...cost];
      const property = name.startsWith("amount")
        ? "amount"
        : name.startsWith("price")
        ? "price"
        : name.startsWith("pimg")
        ? "pimg"
        : name.startsWith("resPrice")
        ? "resPrice"
        : name.startsWith("profit")
        ? "profit"
        : name.startsWith("category")
        ? "category"
        : name.startsWith("cimg")
        ? "cimg"
        : name.startsWith("tag")
        ? "tag"
        : name.startsWith("offer")
        ? "offer"
        : "id";
      updatedCost[index][property] = value;
      setCost(updatedCost);
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleTopBanner = (e) => {
    setTopBanner(e.target.files[0]);
  };
  const handleDescImg = (e) => {
    setDescImg(e.target.files[0]);
  };
  const handleScrollBanners = (e) => {
    setHBanners(Array.from(e.target.files));
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append("name", form?.name);
    formData.append("api", form?.api);
    formData.append("apiName", form?.apiName);
    formData.append("gameName", form?.gameName);
    formData.append("region", form?.region);
    formData.append("tag", form?.tag);
    formData.append("stock", form?.stock);
    formData.append("desc", form?.desc);
    formData.append("descTwo", form?.descTwo);
    formData.append("descThree", form?.descThree);
    formData.append("descImg", descImg);
    formData.append("image", selectedFile);
    formData.append("topBanner", topBanner);
    formData.append("notificationHeading", form?.notificationHeading);
    formData.append("notificationDescOne", form?.notificationDescOne);
    formData.append("notificationDescTwo", form?.notificationDescTwo);
    formData.append("cost", JSON.stringify(cost)); // Stringify the cost array
    formData.append("category", JSON.stringify(form?.category));

    if (Array.isArray(hBanners)) {
      hBanners.forEach((file, index) => {
        formData.append(`hbanners[${index}]`, file);
      });
    }

    setLoading(true);

    try {
      const res = await axios.post("/api/product/add-product", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        navigate("/admin-products");
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  const getMobileLegendGame = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-mobile-legend",
        { region: form?.region },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.success) {
        setData(res.data.data.product);
      } else {
        message.error("Api Error, Try after sometime");
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function getAllImages() {
    try {
      const res = await axios.get("/api/image/get-images");
      if (res.data.success) {
        setImages(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllImages();
  }, []);

  useEffect(() => {
    if (form?.region !== "") {
      getMobileLegendGame();
    }
  }, [form?.region]);

  const fetchServices = async () => {
    try {
      const res = await axios.post(
        "http://localhost:8080/api/yokcash/get-yokcash",
        {
          gameName: form?.gameName,
        }
      );
      if (res.data.success) {
        setYokcash(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (form?.gameName !== "") {
      fetchServices();
    }
  }, [form?.gameName]);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Add Product</h3>
        </div>
        <hr />
        <div className="add-product-container">
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <span className="text-dark">
            <small>Top Banner</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="topBanner"
              onChange={handleTopBanner}
            />
          </div>
          <span className="text-dark">
            <small>Scroll Banners</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="hbanners"
              onChange={handleScrollBanners}
              multiple
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="name"
              onChange={handleChange}
              value={form?.name}
              type="text"
              placeholder="Enter name"
            />
          </div>
          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="desc"
              id=""
              cols="30"
              rows="3"
              placeholder="Product name desc"
              className="form-control"
              onChange={handleChange}
              value={form?.desc}
            ></textarea>
          </div>
          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="descTwo"
              id=""
              cols="30"
              rows="3"
              placeholder="Description"
              className="form-control"
              onChange={handleChange}
              value={form?.descTwo}
            ></textarea>
          </div>
          <span className="text-dark">
            <small>Desc Last</small>
          </span>
          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="descThree"
              id=""
              cols="30"
              rows="3"
              placeholder="Description Down"
              className="form-control"
              onChange={handleChange}
              value={form?.descThree}
            ></textarea>
          </div>
          <span className="text-dark">
            <small>Desc Img</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="descImg"
              onChange={handleDescImg}
            />
          </div>
          <div className="form-fields mb-3">
            <Multiselect
              id="category"
              className="multiselect-wrapper"
              isObject={false}
              onRemove={handleCategoryChange}
              onSelect={handleCategoryChange}
              selectedValues={form.category}
              options={[
                "Popular",
                "Mobile Games",
                "PC Games",
                "Games Vouchers",
                "Social Media",
                "Others",
              ]}
            />
          </div>
          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.api}
              name="api"
              className="w-100"
            >
              <option value="">API BASED?</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          {form?.api === "yes" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.apiName}
                name="apiName"
                className="w-100"
              >
                <option value="">Select API</option>
                <option value="smileOne">Smile One Api</option>
                <option value="yokcash">Yokcash Api</option>
                <option value="moogold">Moogold Api</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "moogold" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                <option value="428075">Genshin Impact</option>
                <option value="4427073">Brawl Stars</option>
                <option value="4427071">Clash of Clans</option>
                <option value="6963">Pubg Global</option>
                <option value="15145">Mobile Legends</option>
                <option value="2134118">Free Fire Max</option>
                <option value="5177311">Honor of Kings</option>
                <option value="4422860">Farlight 84</option>
                {/* <option value="10630085">Pokemon Unite</option> */}
              </select>
            </div>
          )}

          {form?.api === "yes" && form?.apiName === "yokcash" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                <option value="Free Fire">Free Fire</option>
                <option value="Genshin Impact">Genshin Impact</option>
                <option value="Call Of Duty Mobile">Call Of Duty Mobile</option>
                <option value="PUBG Mobile">PUBG Mobile</option>
                <option value="Honkai Star Rail">Honkai Star Rail</option>
                <option value="Valorant">Valorant</option>
                <option value="Clash Of Clans">Clash Of Clans</option>
                <option value="League of Legends Wild Rift">
                  League of Legends Wild Rift
                </option>
                <option value="ML Region Luar">ML Region Luar</option>
                <option value="Mobile Legends">Mobile Legends</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.region}
                name="region"
                className="w-100"
              >
                <option value="">Select Region</option>
                <option value="brazil">Brazil</option>
                <option value="philippines">Philippines</option>
              </select>
            </div>
          )}
          <div className="form-fields mb-3">
            <input
              name="tag"
              type="text"
              placeholder="Enter tag (Optional)"
              className="w-100 form-control"
              onChange={handleChange}
              value={form?.tag}
            />
          </div>
          <div className="form-fields mb-3">
            <label htmlFor="" className="text-dark">
              <small>Stock</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.stock}
              name="stock"
              className="w-100"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          {cost &&
            cost?.map((item, index) => (
              <div className="d-flex form-fields mb-3" key={index}>
                <input
                  className="w-100"
                  name={`id-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.id || ""}
                  type="text"
                  placeholder="Enter id"
                />
                <input
                  className="w-100"
                  name={`amount-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.amount || ""}
                  type="text"
                  placeholder="Enter Pack Details"
                />
                <input
                  className="w-100"
                  name={`price-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.price || ""}
                  type="text"
                  placeholder="Enter Price"
                />
                <input
                  className="w-100"
                  name={`resPrice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.resPrice || ""}
                  type="text"
                  placeholder="Enter Reseller Price"
                />
                <input
                  className="w-100"
                  name={`offer-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.offer || ""}
                  type="text"
                  placeholder="Enter offer in %"
                />
                <input
                  className="w-100"
                  name={`tag-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.tag || ""}
                  type="text"
                  placeholder="Enter tag"
                />
                <input
                  className="w-100"
                  name={`pimg-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.pimg || ""}
                  type="text"
                  placeholder="Enter Image"
                />
                <input
                  className="w-100"
                  name={`category-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.category || ""}
                  type="text"
                  placeholder="Enter category"
                />
                <input
                  className="w-100"
                  name={`cimg-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.cimg || ""}
                  type="text"
                  placeholder="Enter category img"
                />
                <button onClick={() => handleAddCostField(index)}>+</button>
                {index > 0 && (
                  <button onClick={() => handleRemoveCostField(index)}>
                    -
                  </button>
                )}
              </div>
            ))}
          <span className="text-dark">
            <small>Product Notification</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              type="text"
              name="notificationHeading"
              onChange={handleChange}
              placeholder="Product Heading"
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              type="text"
              name="notificationDescOne"
              onChange={handleChange}
              placeholder="Product Desc One"
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              type="text"
              name="notificationDescTwo"
              onChange={handleChange}
              placeholder="Product Desc Two"
            />
          </div>
          <button className="w-100 py-3" onClick={handleAddProduct}>
            Add
          </button>
        </div>
      </div>

      {/* API PRO LIST  */}
      {form?.apiName === "smileOne" && data && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>SPU</th>
              <th>PRICE</th>
              <th>COST PRICE</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.id}</td>
                  <td>{item.spu}</td>
                  <td>{item.price}</td>
                  <td>{item.cost_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {form?.apiName === "yokcash" && yokcash && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {yokcash?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>{item?.nama_layanan}</td>
                  <td>{item?.kategori}</td>
                  <td>{item?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </AdminLayout>
  );
};

export default AdminAddProduct;
