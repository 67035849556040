import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import Slider from "react-slick";
import Timer from "./Home/Timer";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./Games.css";

const Products = ({ title, homeLabel }) => {
  const arrowRef = useRef();
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("All");
  const [flashProducts, setFlashProducts] = useState("");
  const [event, setEvent] = useState(null);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  var settings = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const renderCategory = (filter, maxItems) => {
    const filteredProducts = products?.filter((item) => {
      if (filter === "All") {
        return true;
      } else {
        return item?.category?.includes(filter);
      }
    });

    if (filteredProducts && filteredProducts.length > 0) {
      return (
        <div className="popular-games">
          <div className="titlee">
            <h5>{filter}</h5>
          </div>
          <div className="games-list">
            {filteredProducts.slice(0, maxItems).map((item, index) => (
              <div
                key={index}
                className="game"
                onClick={() => navigate(`/product/${item?.name}`)}
              >
                <img src={`/${item?.image}`} alt="pro-img" />
                <h5>{item?.name}</h5>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };
  async function getProducts() {
    try {
      const res = await axios.get("/api/flash/get-product", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setFlashProducts(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  }
  //  FLASH SALE
  useEffect(() => {
    getProducts();
  }, []);

  const fetchEvent = async () => {
    try {
      const res = await axios.get("/api/admin/get-event", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setEvent(res.data.event);
    } catch (error) {
      console.error("Error fetching event:", error);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  return (
    <>
      {event && event?.display === "yes" && (
        <div className="flash-sale-container">
          <h2>
            Flash Sale
            <FlashOnIcon className="icon" />
          </h2>
          <Timer event={event} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
          <Slider ref={arrowRef} {...settings}>
            {flashProducts &&
              flashProducts?.map((item, index) => {
                return (
                  <Link className="flash-salee" to={item?.link} target="_blank">
                    <div className="fsale">
                      <div className="fimg">
                        <img src={`/${item?.image}`} alt="" />
                      </div>
                      <div className="fcontent">
                        <p>{item?.subHeading}</p>
                        <h5>{item?.heading}</h5>
                        <button className="price">
                          Rs. {item?.price}
                          <span className="ogPrice">{item?.ogPrice}</span>
                        </button>
                        {/* <span className="ogPrice">Rs. {item?.ogPrice}</span> */}
                        <div className="stock">
                          <span>{item?.stock} Stock</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
          </Slider>
          <div className="flash-slider-btns">
            <div onClick={() => arrowRef.current.slickPrev()}>
              <KeyboardArrowLeftIcon className="icon left" />
            </div>
            <div onClick={() => arrowRef.current.slickNext()}>
              <KeyboardArrowRightIcon className="icon right" />
            </div>
          </div>
        </div>
      )}
      <div className="explore-products-container">
        {renderCategory("Popular", 100)}

        <div className="filter-btns">
          <button
            onClick={() => setFilter("All")}
            className={`${filter === "All" && "active"}`}
          >
            All
          </button>
          {products?.filter((item) => item.category.includes("Mobile Games"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Mobile Games")}
              className={`${filter === "Mobile Games" && "active"}`}
            >
              Mobile Games
            </button>
          )}
          {products?.filter((item) => item.category.includes("PC Games"))
            .length > 0 && (
            <button
              onClick={() => setFilter("PC Games")}
              className={`${filter === "PC Games" && "active"}`}
            >
              PC Games
            </button>
          )}
          {products?.filter((item) => item.category.includes("Games Vouchers"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Games Vouchers")}
              className={`${filter === "Games Vouchers" && "active"}`}
            >
              Games Vouchers
            </button>
          )}
          {products?.filter((item) => item.category.includes("Social Media"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Social Media")}
              className={`${filter === "Social Media" && "active"}`}
            >
              Social Media
            </button>
          )}
          {products?.filter((item) => item.category.includes("Others")).length >
            0 && (
            <button
              onClick={() => setFilter("Others")}
              className={`${filter === "Others" && "active"}`}
            >
              Others
            </button>
          )}
        </div>

        {renderCategory(filter, 100)}
      </div>
    </>
  );
};

export default Products;
