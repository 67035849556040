import Layout from "../components/Layout/Layout";
import React, { useEffect, useState } from "react";
import DashboardLayout from "./components/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { message, Pagination } from "antd";
import "./Orders.css";
import StickyFooter from "../components/Home/StickyFooter";

const Orders = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [allOrders, setAllOrders] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [userId, setUserId] = useState("");
  const [date, setDate] = useState("");

  const getAllUserOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/order/get-user-orders",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        const sortedOrders = res.data.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setAllOrders(sortedOrders);
        setData(sortedOrders);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getAllUserOrders();
    }
  }, [user]);

  useEffect(() => {
    let filteredOrders = data;

    if (userId) {
      filteredOrders = filteredOrders?.filter((item) =>
        item.userId?.includes(userId)
      );
    }

    if (date) {
      filteredOrders = filteredOrders?.filter(
        (item) =>
          new Date(item.createdAt).toLocaleDateString() ===
          new Date(date).toLocaleDateString()
      );
    }

    setAllOrders(filteredOrders);

    if (!userId && !date) {
      setAllOrders(data);
    }
  }, [userId, date, data]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const displayedOrders = allOrders?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-order-container">
          {loading ? (
            <div className="loader-container text-center">
              <span className="loader"></span>
            </div>
          ) : (
            <>
              <div className="tools mb-3">
                <div className="form-fields">
                  <input
                    type="text"
                    placeholder="Search User Id"
                    className="p-2"
                    onChange={(e) => setUserId(e.target.value)}
                  />
                </div>
                <div className="form-fields">
                  <input
                    type="date"
                    className="p-2"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
                <button
                  className="bg-danger btn text-white"
                  onClick={() => {
                    setAllOrders(data);
                    setUserId("");
                    setDate("");
                  }}
                >
                  Clear Filter
                </button>
              </div>
              <table className="table table-bordered otb">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>User Id</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedOrders?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.orderId}</td>
                        <td>{item?.userId || item?.playerId}</td>
                        <td>
                          {item?.createdAt
                            ? new Date(item.createdAt).toLocaleString(
                                "default",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )
                            : ""}
                        </td>
                        <td>{item?.status}</td>
                        <td>{parseFloat(item?.price).toFixed(2)}</td>
                        <td>
                          <button
                            onClick={() =>
                              navigate(`/view-order/${item?.orderId}`)
                            }
                            className="view-btn"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                className="my-3"
                current={currentPage}
                pageSize={pageSize}
                total={allOrders?.length}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageSizeChange}
              />
            </>
          )}
        </div>
        <StickyFooter />
      </DashboardLayout>
    </Layout>
  );
};

export default Orders;
